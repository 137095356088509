<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Tipologie Rateali"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :external-filter="externalFilter"
          :filters="filterTypes"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-description="{filter}">
            Descrizione: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento rateali..."
          :headers="headers"
          :loading="loading"
          :items="filteredSchedulePaymentsSettings"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :show-select="false"
          @edit="handleEdit"
          @delete="handleDelete"
          @dblclick:row="handleEditDoubleClick"
        >
        </TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import schedulePaymentsSettingsService from '@/services/schedulePaymentsSettings/schedulePaymentsSettings.service.js'

export default {
  name: "SchedulePaymentsSettingsList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    StandardMenu
  },
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      schedulePaymentsSettings: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data Creazione', label: 'Del giorno', color: "", value: [undefined, undefined] },
        { type: 'number', operator: 'equal', field: 'numberOf', name: 'Numero rate', label: 'Numero rate', color: "", value: undefined },
        { type: 'number', operator: 'equal', field: 'percetageAdvance', name: 'Percentuale Acconto', label: 'Percentuale Acconto', color: "", value: undefined },
        { type: 'number', operator: 'equal', field: 'percetageInstallment', name: 'Percentuale Rata', label: 'Percentuale Rata', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'amountFrom', name: 'Importo da', label: 'Importo da', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'amountTo', name: 'Importo a', label: 'Importo a', color: "", value: undefined },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined },
      filtersValue: [],
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchSchedulePaymentsSettings()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchSchedulePaymentsSettings() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      schedulePaymentsSettingsService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages
        }

        this.schedulePaymentsSettings = results.rows
        this.loadingItems = false
      })
    },
    applyFilters() {
      this.fetchSchedulePaymentsSettings();
    },
    fetchHeaders() {
      this.loadingHeaders = true
      schedulePaymentsSettingsService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(schedulePaymentsSetting) {
      this.$router.push({
        name: 'SchedulePaymentsSettingsEditForm',
        params: {
          id: schedulePaymentsSetting.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'SchedulePaymentsSettingsList'}).href,
          pathName: "SchedulePaymentsSettingsList"
        }
      })
    },
    handleEditDoubleClick(row, {item: schedulePaymentsSetting}) {
      this.$router.push({
        name: 'SchedulePaymentsSettingsEditForm',
        params: {
          id: schedulePaymentsSetting.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'SchedulePaymentsSettingsList'}).href,
          pathName: "SchedulePaymentsSettingsList"
        }
      })
    },
    handleDelete(schedulePaymentsSetting) {
      let confirmed = confirm('Sei sicuro di voler eliminare la rata?')

      if(confirmed) {
        this.loading
        schedulePaymentsSettingsService.delete(schedulePaymentsSetting).then(() => {
          this.fetchSchedulePaymentsSettings()
          this.loading
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'SchedulePaymentsSettingsNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'SchedulePaymentsSettingsList'}).href
        }
      })
    }
  },
  computed: {
    filteredSchedulePaymentsSettings() {
      return this.schedulePaymentsSettings
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchSchedulePaymentsSettings()
    },
    rowPerPage() {
      this.fetchSchedulePaymentsSettings()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>